(function (d, w, c) {
  w.ChatraID = 'YN8Epee53QTWe7cs8';
  const s = d.createElement('script');
  w[c] = w[c] || function () {
    (w[c].q = w[c].q || []).push(arguments);
  };
  s.async = true;
  s.src = (d.location.protocol === 'https:' ? 'https:' : 'http:') + '//call.chatra.io/chatra.js';
  if (d.head) d.head.appendChild(s);
})(document, window, 'Chatra');
